<template>
    <div>
        <div>用水分析</div>

        <div class="cards">
            <Card title="水耗分析">
                <div class="consume">
                    <Consume />
                    <Consume />
                </div>
            </Card>
            <Card title="水耗趋势">
                <Consume />
            </Card>
            <Card title="建筑月水耗占比">
                <Consume />
            </Card>
            <Card title="液位计（二号池）">
            </Card>
        </div>

    </div>
</template>

<script>
import Consume from './consume.vue'

export default {
    components: {
        Consume,
    }
}
</script>

<style lang="less" scoped>
.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;

    .consume {
        padding: 4em;
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
}
</style>